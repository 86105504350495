/* Initial styling */
.wrapper {
  position: fixed;
  top: 10px;
  right: -30px; /* Initially hidden outside the screen */
  padding: 10px 16px;
  z-index: 55;
  background-color: #ed4337;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  opacity: 0; /* Start with invisible */
  visibility: hidden; /* Ensure it’s not visible at all */
  transition: opacity 0.5s ease, visibility 0s 0.5s; /* Transition opacity and visibility */
}

.wrapper.show {
  right: 10px; /* Position it inside the screen */
  opacity: 1; /* Fade in */
  visibility: visible; /* Make it visible */
  animation: slideIn 0.5s forwards; /* Coming from right animation */
}

.wrapper.hide {
  animation: slideOut 0.5s forwards; /* Going out animation */
  opacity: 0; /* Fade out */
  visibility: hidden; /* Hide when it disappears */
  transition: visibility 0s 0.5s; /* Delay visibility change until animation ends */
}

/* Animation for coming from right */
@keyframes slideIn {
  0% {
    right: -30px;
  }
  100% {
    right: 10px;
  }
}

/* Animation for going back to right */
@keyframes slideOut {
  0% {
    right: 10px;
  }
  100% {
    right: -30px;
  }
}
