.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
}

.modal_content {
  background-color: white;
  padding: 10px 0;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  width: 95%;
  max-width: 760px;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;
}

.close {
  font-size: 24px;
  cursor: pointer;
  color: #ccc;
}

.close:hover {
  color: #000;
}

.upload_box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  margin: 20px;
}
.upload_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f46e5;
  border: none;
  cursor: pointer;
  font-size: 24px;
  background-color: transparent;
}

.upload_icon {
  margin-bottom: 10px;
  background-color: #f9fafb;
  border-radius: 28px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload_icon_inner {
  background-color: #f2f4f7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}

.upload_icon_inner svg {
  color: #475467;
  width: 24px;
  height: 24px;
}

.upload_text {
  color: #4f46e5;
}

.drag_active {
  border: 2px dashed #00aaff; /* Highlighted border color */
}

.btn {
  padding: 10px 20px;
  background-color: #4f46e5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 100px;
}
.files {
  /* margin: 0 5px;
  padding: 10px; */
}
.line {
  /* margin: 5px; */
  border-bottom: 1px solid lightgray;
}

.files_wrapper {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  overflow: auto;
  height: 250px;
}
.files_wrapper::-webkit-scrollbar {
  width: 5px;
}
.files_wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}
.files_wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(79, 70, 229);
  border-radius: 10px;
  border: none;
}
.file_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.selected {
  background-color: #4f46e50d;
}
.file_upload_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 10px;
}
.file_name {
  color: gray;
  text-transform: capitalize;
  word-break: break-all;
  text-overflow: ellipsis;
  max-width: 290px;
  width: 100%;
  font-size: 14px;
}
.file_options {
  display: flex;
  align-items: center;
  gap: 10px;
}
.submit_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 10px 0 0px;
  gap: 20px;
}

.loader_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 5000;
}
.loader_content {
  width: 100%;
  max-width: 400px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  min-height: 250px;
  background-color: white;
  border-radius: 10px;
}
.loader_content p {
  font-weight: 700;
  font-size: 20px;
}
.loader {
  height: 7px;
  width: 80%;
  --c: no-repeat linear-gradient(#6100ee 0 0);
  background: var(--c), var(--c), #d7b8fc;
  background-size: 60% 100%;
  animation: l16 3s infinite;
}
@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}

/* search box */

.stock_search {
  position: relative;
  width: 200px;
}
.stock_search_input {
  width: 100%;
}

.resultsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #d0d5dd;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  z-index: 30;
  max-height: 200px;
  overflow-y: auto;
}

.resultsContainer::-webkit-scrollbar {
  width: 5px;
}
.resultsContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
.resultsContainer::-webkit-scrollbar-thumb {
  background-color: rgb(79, 70, 229);
  border-radius: 10px;
  border: none;
}
.resultItem {
  font-size: 14px;

  padding: 8px;
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.308);
}
.resultItem .company_name {
  /* font-weight: bold; */
}

.selectedResultItem {
  background-color: #4f46e5;
  color: white;
}

.checkbox {
  accent-color: #4f45e4;
}
