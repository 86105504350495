/* src/Chat.css */
.chat_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  scrollbar-color: transparent transparent;
}

.chat_messages {
  overflow-y: auto;
  margin-bottom: auto;
  padding: 10px;
  height: 100%;
}

.chat_message {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  max-width: 80%;
  word-wrap: break-word;
}

.chat_message.user {
  top: 268px;
  left: 579px;
  padding: 16px;
  gap: 0px;
  border-radius: 8px;
  justify-content: space-between;
  background-color: #f0f2f4;
  margin-bottom: 30px;
  margin-left: auto;
}

.chat_message.bot {
  top: 344px;
  right: 817px;
  gap: 10px;
  border-radius: 8px;
  background-color: #d9d9d9;
  margin-bottom: 30px;
  padding: 16px;
  background-color: #eef2ff;
}

.chat_controls {
  padding: 10px;
}

.prompt {
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
}
.prompt input {
  display: inline-block;
  padding: 20px 20px 20px 40px;
  width: 100%;
  outline: none;
  border: none;
}
.prompt span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.prompt .left {
  left: 10px;
}
.prompt .right {
  right: 10px;
}

.answer {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.question {
  text-align: left;
}
.answer_head {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  text-wrap: wrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.sources {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  color: #4f46e5;
  font-size: 14px;
}
.source {
  cursor: pointer;
  border-radius: 10px;

  /* border: 1px solid gray; */
  background: #fff;
  padding: 5px 10px;
  text-transform: capitalize;
}
.source_info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.src_page {
  color: gray;
  display: none;
}

/* markdown css */
.reactMarkDown {
  text-wrap: wrap;
  text-align: left;
  padding-top: 10px;
}

.reactMarkDown h1 {
  margin: 20px 0 10px 0;
}
.reactMarkDown h2 {
  margin: 18px 0 9px 0;
}
.reactMarkDown h3 {
  margin: 16px 0 8px 0;
}
.reactMarkDown h4 {
  margin: 14px 0 7px 0;
}
.reactMarkDown ul,
ol {
  margin-top: 1em;
  margin-bottom: 1em;
  list-style: disc outside none;
  font-size: 14px;
}

.reactMarkDown ul li,
ol li {
  margin-left: 2em;
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 5px;
}
.reactMarkDown p {
  font-size: 14px;
  margin: 10px 5px;
  white-space: pre-wrap;
}

.reactMarkDown table,
tr,
td,
th {
  border: 1px solid lightgray;
  border-collapse: collapse;
  padding: 5px;
}
