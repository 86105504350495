.suggestion-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* padding-bottom: 20px; */
  gap: 20px;
}
.suggestion-item {
  flex-grow: 1;
  /* width: 22vw; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  padding: 15px;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #f9fafb;
  cursor: pointer;
}
.suggestion-item-text {
  max-width: 95%;
}
