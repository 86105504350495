/* filter.module.css */
.container {
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  z-index: 1000;
  width: 170px;
  position: absolute;
  right: 50%;
  top: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radioOption {
  padding: 10px 8px;
}

.radioButton {
  margin-right: 10px;
  accent-color: #4f46e5;
}

.hr {
  margin: 0;
  border: none;
  border-top: 1px solid #d0d5dd;
  width: 100%;
}
