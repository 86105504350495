.wrapper {
  height: 50%;
  border-bottom: 1px solid #cccccc;
  overflow: hidden auto;
  padding: 0 10px;
  scrollbar-width: 5px;
  scrollbar-color: rgb(79, 70, 229) transparent;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  transition: background-color 0.3s ease;
  position: sticky;
  left: 0;
  top: 0;
  background-color: white;
}

.filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  gap: 20px;
}
.filterIcon {
  cursor: pointer;
  color: #475467;
  padding: 0;
}
.docs_wrapper {
  width: 100%;
}
.doc_wrapper {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  transition: background-color 0.3s ease;
}

.expanded_content {
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  padding-bottom: 15px;
  text-decoration: underline;
  color: #4f46e5;
  font-weight: 500;
}

.checkbox_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.checkbox {
  width: 20px;
  margin-right: 5px;
  accent-color: #4f46e5;
  cursor: pointer;
}

.label {
  font-size: 14px;
  margin-left: -10px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-width: 90%;
  text-transform: capitalize;
}

.noData {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
}
.upload_btn {
  border: 1px solid #4f46e5;
  color: #4f46e5;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500px;
  border-radius: 4px;
  padding: 5px 8px;
  background-color: #f3f3fc;
  cursor: pointer;
}
