.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0f0d24;
}
.heading {
  font-size: 3.5rem;
  font-weight: 700;
  background: -webkit-linear-gradient(#f3f3f3, #8d8d8d);
  text-align: center;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
}
.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  /* background-color: white; */
  color: white;
  border-radius: 10px;
  width: 100%;
  /* max-width: 500px; */
}
.description {
  /* color: white; */
  text-align: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  max-width: 600px;
  width: 90%;
  transform: translate(-50%, 0);
  line-height: 1.4;
  letter-spacing: 1.6px;
  background: -webkit-linear-gradient(#f3f3f3, #8d8d8d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.description a {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
@media screen and (max-width: 700px) {
  .heading {
    font-size: 2.4rem;
  }
}
