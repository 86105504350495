.pdf_viewer__info {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.pdf_viewer__controls {
  display: flex;
  align-items: center;
  color: #475467;
  gap: 15px;
  cursor: pointer;
}
.pdf_view {
  margin: 0 10px;
}
.pdf_close_btn {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
}
