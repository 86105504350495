.prompt {
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  position: relative;
  width: calc(100% - 20px);
  display: flex;
  align-items: stretch;
  margin: 5px 10px 10px 10px;
  margin-top: auto;
  /* overflow: hidden; */
}

.editor_container {
  display: inline-block;
  width: 100%;
  max-height: 250px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: text;
  position: relative;
  overflow: auto;
}

.editor_container:empty:not(:focus)::before {
  content: attr(
    data-placeholder
  ); /* Use the data-placeholder attribute for text */
  color: #aaa;
  position: absolute;
  pointer-events: none;
}

.editor_container:focus {
  outline: none;
  border-color: #0078d7;
}

.send_btn,
.icon {
  padding: 10px;
  height: 40px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
}
.send_btn {
  cursor: pointer;
}

.loader {
  height: 25px;
  width: 25px;
  border: 5px solid transparent;
  margin: 5px;
  border-bottom-color: rgb(79, 70, 229);
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
