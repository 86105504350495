.wrapper {
  height: 50%;
  border-bottom: 1px solid #cccccc;
  overflow-y: auto;
  scrollbar-width: 5px;
  scrollbar-color: rgb(79, 70, 229) transparent;
  position: relative;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background-color 0.3s ease;
  position: sticky;
  z-index: 20;
  background: white;
  left: 0;
  top: 0;
  width: 100%;
}

.chat_month {
  position: sticky;
  top: 35px;
  padding: 10px;
  background-color: white;
  width: 100%;
  font-size: 16px;
}
.monthly_chats {
  margin: 0 10px;
  max-width: calc(100% - 5px);
  width: 100%;
}

.chat_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background-color 0.3s ease;
  text-transform: capitalize;
  /* max-width: 95%; */
  max-width: calc(100% - 5px);
  width: 95%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  color: black;
  text-decoration: none;
}
.chat_item:hover {
  background-color: #f9fafb;
  cursor: pointer;
  border-radius: 8px;
}
.active_chat {
  background-color: #f0f2f4;
  border-radius: 8px;
}

.new_chat {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: gray;
  font-weight: 700;
  font-size: 20px;
}

.pointer {
  cursor: pointer;
}
.new_chat_btn {
  border: 1px solid #4f46e5;
  color: #4f46e5;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  font-weight: 500px;
  border-radius: 4px;
  padding: 5px 8px;
  background-color: #f3f3fc;
  cursor: pointer;
}
