.container {
  display: flex;
  height: 100vh;
  max-height: 100vh;
}

.mainContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  padding: 15px 30px;
  background-color: transparent;
  gap: 20px;
}

.content {
  display: flex;
  overflow: hidden;
}

.leftPanel {
  min-width: 250px;
  max-width: 500px;
  height: calc(100vh - 64.5px);
  overflow-x: auto;
  flex-shrink: 0;
  position: relative;
}
.resizer {
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #ddd;
  cursor: ew-resize;
  z-index: 25;
}

.rightPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64.5px);
  gap: 5px;
}

.filterIcon {
  cursor: pointer;
  color: #475467;
}

.asideContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .leftPanelHidden {
    display: none;
  }
  .leftPanelShow {
    display: inherit;
  }
}
