.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1c2b41;
  flex-direction: column;
  gap: 20px;
}
.content {
  color: white;
  font-size: 1.8rem;
}
