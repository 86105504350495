.container_header {
  padding: 20px 20px 20px 10px;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  gap: 20px;
}
.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}
.info {
  display: flex;
  font-weight: 700;
  font-size: 18px;
  align-items: center;
  gap: 5px;
}
.changes {
  color: #1fa835;
  font-size: 14px;
}
.timeframe {
  font-size: 14px;
  color: #666666;
}
