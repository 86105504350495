.left_nav_bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  height: 100vh;
  background-color: #1c2b41;
  padding: 20px 0;
  box-sizing: border-box;
  overflow: hidden;
  transition: width 0.3s;
}

.logo {
  width: 60px;
  height: 60px;
}

.nav_tabs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav_tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  margin: 10px 0;
  position: relative;
  padding: 10px;
  transition: background-color 0.3s, border-radius 0.3s;
}

.nav_tab svg {
  font-size: 25px;
}

.tab_text {
  display: none;
  position: absolute;
  left: 70px;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  transition: display 0.3s;
}

.nav_tab:hover {
  background-color: #2a3b57;
  border-radius: 8px;
}

.nav_tab:hover .tab_text {
  display: block;
}

.nav_tab.active {
  background-color: #304c7d;
  border-radius: 8px;
}

.bottom_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .left_nav_bar {
    width: 50px;
  }

  .logo {
    width: 30px;
    height: 30px;
  }

  .tab_text {
    left: 60px;
  }
}

@media (max-width: 480px) {
  .left_nav_bar {
    width: 40px;
  }

  .logo {
    width: 20px;
    height: 20px;
  }

  .nav_tab {
    margin: 5px 0;
  }

  .tab_text {
    left: 50px;
  }
}
