/* searchBar.module.css */

.searchBar {
  display: flex;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 8px;
  position: relative;
  width: 500px;
  gap: 4px;
}

.searchIcon {
  margin-right: 8px;
  color: #667085;
}

.input {
  border: none;
  outline: none;
  width: 100%;
  color: #000000;
  padding-right: 30px;
}

.clearIcon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #667085;
}

.resultsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #d0d5dd;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  z-index: 30;
  max-height: 200px;
  overflow-y: auto;
}

.resultsContainer::-webkit-scrollbar {
  width: 5px;
}
.resultsContainer::-webkit-scrollbar-track {
  background-color: transparent;
}
.resultsContainer::-webkit-scrollbar-thumb {
  background-color: rgb(79, 70, 229);
  border-radius: 10px;
  border: none;
}
.resultItem {
  padding: 8px;
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.308);
}

.ticker_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
}
.resultItem .company_name {
  font-weight: bold;
}

.selectedResultItem {
  background-color: #4f46e5;
  color: white;
}

.noData {
  padding: 8px;
  text-align: center;
  color: #000000;
}
